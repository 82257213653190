var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DarkWhite"},[_c('v-data-table',{staticClass:"elevation-1 pt-2",attrs:{"headers":_vm.headers,"items":_vm.parList,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mr-2 mt-2",staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","placeholder":"Search PAR/RIS/IAR number","color":"green darken-2","dense":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-spacer'),_c('v-autocomplete',{staticClass:"mr-2 mt-2",staticStyle:{"max-width":"200px"},attrs:{"color":"green","item-text":"name","item-value":"UserID","items":_vm.userList,"dense":"","hide-details":"","label":"Filter By User"},model:{value:(_vm.userID),callback:function ($$v) {_vm.userID=$$v},expression:"userID"}}),_c('v-autocomplete',{staticClass:"mr-2 mt-2",staticStyle:{"max-width":"200px"},attrs:{"color":"green","item-text":"description","item-value":"id","items":_vm.doc_types,"dense":"","hide-details":"","label":"Filter By Type"},model:{value:(_vm.typeID),callback:function ($$v) {_vm.typeID=$$v},expression:"typeID"}}),_c('v-autocomplete',{staticClass:"mr-2 mt-2",staticStyle:{"max-width":"200px"},attrs:{"color":"green","item-text":"description","item-value":"id","items":_vm.remarksList,"dense":"","hide-details":"","label":"Filter By Remarks"},model:{value:(_vm.remarksID),callback:function ($$v) {_vm.remarksID=$$v},expression:"remarksID"}})],1)]},proxy:true},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.totalAmount)))])]}},{key:"item.DateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateCreated))+" ")]}},{key:"item.Remarks",fn:function(ref){
var item = ref.item;
return [(item.RenewID)?_c('span',[_vm._v("Renewed")]):(item.TransferID)?_c('span',[_vm._v("Transferred")]):_c('span',[_vm._v("First")])]}},{key:"item.DispenseTypeID",fn:function(ref){
var item = ref.item;
return [(item.DispenseTypeID == 1)?_c('span',[_vm._v("Distributed")]):_vm._e(),(item.DispenseTypeID == 2)?_c('span',[_vm._v("Shared")]):_vm._e()]}},{key:"item.Other_Actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[(item.IsCancelled == 0 && item.IssuedStatusID == 7)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"green darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.processPAR(item)}}},[_vm._v(" RTR ")]):(item.IsCancelled == 0 && item.IssuedStatusID != 7)?_c('span',{staticClass:"grey--text"},[_vm._v("For Approval")]):_c('span',{staticClass:"red--text"},[_vm._v("Cancelled")])],1)]}},{key:"item.IsPrinted",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"header"},on:{"change":function($event){return _vm.selectPrinted(item)}},model:{value:(item.IsPrinted),callback:function ($$v) {_vm.$set(item, "IsPrinted", $$v)},expression:"item.IsPrinted"}})]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.viewPAR(item)}}},[_vm._v(" View ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"amber darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.references(item)}}},[_vm._v(" References ")]),(item.IsCancelled == 0)?_c('v-btn',{attrs:{"color":"red darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.printPAR(item)}}},[_vm._v(" Print ")]):_vm._e()],1)]}}],null,true)}),_c('PARModal',{attrs:{"parData":_vm.parData}}),_c('ProcessPARModal',{attrs:{"parData":_vm.processData}}),_c('ReferencesModal',{attrs:{"data":_vm.referenceData}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }