<template>
  <div class="DarkWhite">
    <v-data-table
      :headers="headers"
      :items="parList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search PAR/RIS/IAR number"
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            color="green"
            v-model="userID"
            item-text="name"
            item-value="UserID"
            :items="userList"
            dense
            hide-details
            class="mr-2 mt-2"
            style="max-width:200px;"
            label="Filter By User"
          >
          </v-autocomplete>
          <v-autocomplete
            color="green"
            v-model="typeID"
            item-text="description"
            item-value="id"
            :items="doc_types"
            dense
            hide-details
            class="mr-2 mt-2"
            style="max-width:200px;"
            label="Filter By Type"
          >
          </v-autocomplete>
          <v-autocomplete
            color="green"
            v-model="remarksID"
            item-text="description"
            item-value="id"
            :items="remarksList"
            dense
            hide-details
            class="mr-2 mt-2"
            style="max-width:200px;"
            label="Filter By Remarks"
          >
          </v-autocomplete>
        </v-toolbar>
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.totalAmount) }}</span>
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.Remarks`]="{ item }">
        <span v-if="item.RenewID">Renewed</span>
        <span v-else-if="item.TransferID">Transferred</span>
        <span v-else>First</span>
      </template>
      <template v-slot:[`item.DispenseTypeID`]="{ item }">
        <span v-if="item.DispenseTypeID == 1">Distributed</span>
        <span v-if="item.DispenseTypeID == 2">Shared</span>
      </template>
      <template v-slot:[`item.Other_Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn
          color="green darken-2"
          class="mr-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="processPAR(item)"
          v-if="item.IsCancelled == 0 && item.IssuedStatusID == 7"
        >
          RTR
        </v-btn>
        <span class="grey--text" v-else-if="item.IsCancelled == 0 && item.IssuedStatusID != 7" >For Approval</span>
        <span class="red--text" v-else >Cancelled</span>
        </span>
      </template>
      <template v-slot:[`item.IsPrinted`]="{ item }">
        <v-checkbox v-model="item.IsPrinted" color="header" @change="selectPrinted(item)"></v-checkbox>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="viewPAR(item)"
          >
            View
          </v-btn>
          <v-btn
            color="amber darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="references(item)"
          >
            References
          </v-btn>
          <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="printPAR(item)"
            v-if="item.IsCancelled == 0"
          >
            Print
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <PARModal :parData="parData" />
    <ProcessPARModal :parData="processData" />
    <ReferencesModal :data="referenceData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    PARModal: () => import("./ViewPAR.vue"),
    ProcessPARModal: () => import("./ProcessPAR.vue"),
    ReferencesModal: () => import("./ReferenceICSPAR.vue"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    headers: [
      {
          text: 'PAR CODE',
          align: 'start',
          sortable: false,
          value: 'PARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'PARPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'end',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Dispense Type',
          align: 'center',
          sortable: false,
          value: 'DispenseTypeID',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Remarks',
          align: 'center',
          sortable: false,
          value: 'Remarks',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
    isLoading: false,
    parList: [],
    parData: [],
    renewData: [],
    transferData: [],
    processData: [],
    referenceData: [],
    remarksList: [
        {id: 0, description: "All"},
        {id: 1, description: "First"},
        {id: 2, description: "Renewed"},
        {id: 3, description: "Transferred"},
      ],
    remarksID: 0,
    userList: [],
    userID: 0,
    doc_types: [
        { id: 0, description: "All" },
        { id: 1, description: "Systemized" },
        { id: 2, description: "Old (Uploaded files)" },
      ],
    typeID: 0,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    remarksID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    userID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    typeID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  created() {
    if(this.$store.state.user.roleID == 6 || this.$store.state.user.roleID == 15 || this.$store.state.user.roleID == 16 || this.$store.state.user.roleID == 17 || this.$store.state.user.roleID == 20){
      this.headers = [
      {
          text: 'PAR CODE',
          align: 'start',
          sortable: false,
          value: 'PARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'PARPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'end',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Dispense Type',
          align: 'center',
          sortable: false,
          value: 'DispenseTypeID',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Remarks',
          align: 'center',
          sortable: false,
          value: 'Remarks',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'Other_Actions',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Printed?',
          align: 'center',
          sortable: false,
          value: 'IsPrinted',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ];
    }
    else{
      this.headers = [
      {
          text: 'PAR CODE',
          align: 'start',
          sortable: false,
          value: 'PARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'PARPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'end',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Dispense Type',
          align: 'center',
          sortable: false,
          value: 'DispenseTypeID',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Remarks',
          align: 'center',
          sortable: false,
          value: 'Remarks',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ];
    }
  },
  mounted() {
    this.eventHub.$on("closeViewPAR", () => {
      this.initialize();
    });
    this.eventHub.$on("closeProcessPAR", () => {
      this.initialize();
    });
    this.eventHub.$on("closeReference", () => {
      this.initialize();
    });
    this.getUsers();
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewPAR");
    this.eventHub.$off("closeReference");
    this.eventHub.$off("closeProcessPAR");
  },
  methods: {
    viewPAR(item) {
      this.parData = item;
    },
    printPAR(item) {
      this.link = this.apiUrl + "/par/report/" + item.PARID;
      window.open(this.link);
    },
    renew(item) {
      this.renewData = item;
    },
    transfer(item) {
      this.transferData = item;
    },
    processPAR(item) {
      this.processData = item;
    },
    references(item) {
      this.referenceData = {...item, type: 2};
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("RemarksID", this.remarksID);
      data.append("UserID", this.userID);
      data.append("TypeID", this.typeID);
      this.axiosCall("/getPARList", "POST", data).then((res) => {
        this.loading = false;
        this.parList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    getUsers() {
      this.axiosCall("/getAllPersonsAccountable", "GET").then((res) => {
        this.userList = [{UserID: 0, name: "All"},...res.data.data];
      });
    },
    selectPrinted(item) {
      let data = new FormData();
      data.append("id", item.PARID);
      data.append("table", 'par');
      data.append("column", 'PARID');
      data.append("IsPrinted", item.IsPrinted ? 1 : 0);
      this.axiosCall("/setPrinted", "POST", data).then((res) => {
        if(res.data.status){
          let msg = item.IsPrinted ? 'printed.' : 'not printed.';
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = item.PARCODE + " was successfully set to " + msg;
          this.fadeAwayMessage.header = "System Message";
        }
        else{
          item.IsPrinted = !item.IsPrinted;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
      });
    },
  },
};
</script>